import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import stylesSelect from './styles';

// componentes
import CreatableSelect from 'react-select/lib/Creatable';

function RefeicaoDescricao({
    value,
    onChange,
    onBlur
}){

    // sugestões
    let [sugestoes] = useState([
        'Café da manhã',
        'Almoço',
        'Café da tarde',
        'Jantar'
    ]);

    return <CreatableSelect
        // menuIsOpen={true}
        styles={stylesSelect}
        isClearable
        autoFocus
        defaultMenuIsOpen={true}
        onBlur={onBlur}
        onChange={(newValue, actionMeta) => {
            let descricao = ''
            if(newValue !== null){
                descricao = newValue.label;
            }

            onChange(descricao)
        }}
        onKeyDown={e => {
            if(e.keyCode === 13){
                onBlur();
            }
        }}
        value={{label: value, value: value}}
        options={sugestoes.map(sugestao => 
            // verifica o que já está em uso e não mostrar
            {return {label: sugestao, value: sugestao}}
        )}
    />

}

export default RefeicaoDescricao;