import React from 'react';

// componentes
import { Form, Col } from 'react-bootstrap';
import InputDecimal from './../../../../ComponentesCadastro/InputDecimal';

function Formulario({
    dados,
    alterarDados,
    modoLeitura,
    titulo
}){

    return <>
        <p className='text-center font-weight-bold'>{titulo}</p>
        <hr />
        
        <Form.Row>
            <Form.Label column lg='8'>Percentual de gordura corporal total</Form.Label>
            <Col lg='4'>
                <InputDecimal 
                    valor={dados.percentual_gordura_corporal_total || '0.0'}
                    onChange={valorNovo => {
                        dados.percentual_gordura_corporal_total = valorNovo;
                        alterarDados({...dados});

                    }}
                    casas='2'
                    disabled={modoLeitura}
                />
            </Col>
        </Form.Row>
        
        <Form.Row className='mt-2'>
            <Form.Label column lg='8'>Peso de gordura corporal total</Form.Label>
            <Col lg='4'>
                <InputDecimal 
                    valor={dados.peso_gordura_corporal_total || '0.0'}
                    onChange={valorNovo => {
                        dados.peso_gordura_corporal_total = valorNovo;
                        alterarDados({...dados});

                    }}
                    casas='3'
                    disabled={modoLeitura}
                />
            </Col>
        </Form.Row>
        
        <Form.Row className='mt-2'>
            <Form.Label column lg='8'>Percentual de gordura adequado</Form.Label>
            <Col lg='4'>
                <InputDecimal 
                    valor={dados.percentual_gordura_adequado || '0.0'}
                    onChange={valorNovo => {
                        dados.percentual_gordura_adequado = valorNovo;
                        alterarDados({...dados});

                    }}
                    casas='2'
                    disabled={modoLeitura}
                />
            </Col>
        </Form.Row>
        
        <Form.Row className='mt-2'>
            <Form.Label column lg='8'>Índice de massa corporal (IMC)</Form.Label>
            <Col lg='4'>
                <InputDecimal 
                    valor={dados.indice_massa_corporal || '0.0'}
                    onChange={valorNovo => {
                        dados.indice_massa_corporal = valorNovo;
                        alterarDados({...dados});

                    }}
                    casas='2'
                    disabled={modoLeitura}
                />
            </Col>
        </Form.Row>
        
        <Form.Row className='mt-2'>
            <Form.Label column lg='8'>Taxa de metabolismo basal</Form.Label>
            <Col lg='4'>
                <InputDecimal 
                    valor={dados.taxa_metabolismo_basal || '0.0'}
                    onChange={valorNovo => {
                        dados.taxa_metabolismo_basal = valorNovo;
                        alterarDados({...dados});

                    }}
                    casas='2'
                    disabled={modoLeitura}
                />
            </Col>
        </Form.Row>
        
        <Form.Row className='mt-2'>
            <Form.Label column lg='8'>Peso de massa corporal magra</Form.Label>
            <Col lg='4'>
                <InputDecimal 
                    valor={dados.peso_massa_corporal_magra || '0.0'}
                    onChange={valorNovo => {
                        dados.peso_massa_corporal_magra = valorNovo;
                        alterarDados({...dados});

                    }}
                    casas='3'
                    disabled={modoLeitura}
                />
            </Col>
        </Form.Row>
        
        <Form.Row className='mt-2'>
            <Form.Label column lg='8'>Peso ideal</Form.Label>
            <Col lg='4'>
                <div className='d-flex align-items-center'>
                    <InputDecimal 
                        valor={dados.peso_ideal || '0.0'}
                        onChange={valorNovo => {
                            dados.peso_ideal = valorNovo;
                            alterarDados({...dados});

                        }}
                        casas='3'
                        disabled={modoLeitura}
                    />
                    <p className='my-0 mx-2'>a</p>
                    <InputDecimal 
                        valor={dados.peso_ideal_maximo || '0.0'}
                        onChange={valorNovo => {
                            dados.peso_ideal_maximo = valorNovo;
                            alterarDados({...dados});

                        }}
                        placeholder='Até'
                        casas='3'
                        disabled={modoLeitura}
                    />
                </div>
            </Col>
        </Form.Row>
        
        <Form.Row className='mt-2'>
            <Form.Label column lg='8'>Percentual de massa magra ideal</Form.Label>
            <Col lg='4'>
                <InputDecimal 
                    valor={dados.percentual_massa_magra_ideal || '0.0'}
                    onChange={valorNovo => {
                        dados.percentual_massa_magra_ideal = valorNovo;
                        alterarDados({...dados});

                    }}
                    casas='2'
                    disabled={modoLeitura}
                />
            </Col>
        </Form.Row>
        
        <Form.Row className='mt-2'>
            <Form.Label column lg='8'>Quantidade de água corporal</Form.Label>
            <Col lg='4'>
                <InputDecimal 
                    valor={dados.quantidade_agua_corporal || '0.0'}
                    onChange={valorNovo => {
                        dados.quantidade_agua_corporal = valorNovo;
                        alterarDados({...dados});

                    }}
                    casas='2'
                    disabled={modoLeitura}
                />
            </Col>
        </Form.Row>
        
        <Form.Row className='mt-2'>
            <Form.Label column lg='8'>Percentual de água corporal</Form.Label>
            <Col lg='4'>
                <InputDecimal 
                    valor={dados.percentual_agua_corporal || '0.0'}
                    onChange={valorNovo => {
                        dados.percentual_agua_corporal = valorNovo;
                        alterarDados({...dados});

                    }}
                    casas='2'
                    disabled={modoLeitura}
                />
            </Col>
        </Form.Row>
        
        <Form.Row className='mt-2'>
            <Form.Label column lg='8'>Percentual adequado de água</Form.Label>
            <Col lg='4'>
                <div className='d-flex align-items-center'>
                    <InputDecimal 
                        valor={dados.percentual_adequado_agua || '0.0'}
                        onChange={valorNovo => {
                            dados.percentual_adequado_agua = valorNovo;
                            alterarDados({...dados});

                        }}
                        casas='2'
                        disabled={modoLeitura}
                    />
                    <p className='my-0 mx-2'>a</p>
                    <InputDecimal 
                        valor={dados.percentual_adequado_agua_maximo || '0.0'}
                        onChange={valorNovo => {
                            dados.percentual_adequado_agua_maximo = valorNovo;
                            alterarDados({...dados});

                        }}
                        casas='2'
                        disabled={modoLeitura}
                    />
                </div>
            </Col>
        </Form.Row>

        
        <Form.Group className='mt-2'>
            <Form.Label>Conclusão</Form.Label>
            <Form.Control 
                value={dados.conclusao || ''}
                onChange={e => {
                    dados.conclusao = e.target.value;
                    alterarDados({...dados});
                }}
                as='textarea'
                maxLength='500'
                disabled={modoLeitura}
            />
        </Form.Group>
    </>
}

export default Formulario;