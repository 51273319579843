import React, { useState, useEffect } from 'react';

// componentes
import { Form } from 'react-bootstrap';

function InputDecimal({
    valor,
    onChange,
    disabled=false,
    className='',
    onkeyDown=null,
    casas=2,
    cortarZeros=false,
    size=null,
    placeholder=''
}){

    // variaveis
    let [mascara, alterarMascara] = useState(0);
    let [focado, alterarFoco] = useState(false);
    let inputRef = React.createRef();

    // altera a mascara
    useEffect(() => {
        
        criarMascara();
    }, [])

    useEffect(() => {
        criarMascara();
    //     // alterarValorDigitado(valor.replace('.', ','));
    }, [valor]);

    useEffect(() => {
        if(focado){
            inputRef.current.select();
        }
    }, [focado]);

    function criarMascara(){

        let mascara = valor.replace('.', ',');
        if(cortarZeros){
            mascara = parseFloat(valor).toString().replace('.', ',');
        }
        
        alterarMascara(mascara);
        
    }
    
    return <Form.Control 
        size={size}
        disabled={disabled}
        type="text" 
        // style={{width: '110px'}}
        className={className}
        ref={inputRef}
        value={focado ? valor.replace('.', ',') : mascara}
        placeholder={placeholder}
        onKeyDown={(e) => {
            
            if([37, 39, 9].includes(e.keyCode)){
                return e;
            }

            if (e.which !== 8 && e.which !== 0 && e.which !== 188 && (e.which < 48 || e.which > 57) && (e.which < 96 || e.which > 105)) {
                e.preventDefault();
            }

            if(e.keyCode === 13){
                e.target.blur();
            }

            if(e.keyCode === 9){
                e.target.blur();
            }

            if(e.keyCode === 27){
                e.target.blur();
            }            
            
            if(onkeyDown){
                onkeyDown(e);
            }

            
        }}
        onChange={(e) => {
            let vlorAtualizar = e.target.value === '' ? '0,0' : e.target.value;
            onChange(vlorAtualizar.split('.').join('').replace(',', '.'));
            
        }} 
        onFocus={(e) => {
            alterarFoco(true);
        }}
        onBlur={(e) => {
            let valorCorrigido = parseFloat(e.target.value.split('.').join('').replace(',', '.')).toFixed(casas);
            if(valorCorrigido === 'NaN'){
                valorCorrigido = '0.0';
            }
            onChange(valorCorrigido);
            alterarFoco(false);
            criarMascara();
        }}
    />
}


export default InputDecimal;