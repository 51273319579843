import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

// componentes
import { Button, Card, Row, Col, Form, Tab, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormularioHabitosVida from './FormularioHabitosVida';
import FormularioAvaliacaoClinica from './FormularioAvaliacaoClinica';
import FormularioHabitosAlimentares from './FormularioHabitosAlimentares';

// faz cadastro
export default function Cadastrar(){

    // dados
    const history = useHistory();
    const { id, idpaciente, idagendamento } = useParams();
    let [carregando, alterarCarregando] = useState(false);
    let [modo, alterarModo] = useState('cadastro'); // cadastro, edicao
    let [dados, alterarDados] = useState({
        pk_cad_anamnese: id || null,
        caso_clinico: null,
        refeicoes_fora_casa: 'N',
        refeicao_vezes_semana: null,
        vegetariano: '1',
        tipo_vegetariano: null, // atenção
        alcool_ingestao: 'N',
        alcool_frequencia: null,
        alcool_observacao: null,
        tabagismo_fumante: 'N',
        tabagismo_frequencia: null,
        mora_quantas_pessoas: '0', // inteiro
        compras_quem_realiza: null,
        compras_onde_realiza: null,
        compras_quantas_vezes_mes: null, // inteiro
        compras_leva_filhos: 'N',
        litros_oleo_mes: null, // decimal
        kg_sal_mes: null, // decimal
        sono: '1',
        sono_quantas_horas: null, // time
        sono_observacao: null,
        exercicios_fisicos_quais: null,
        exercicios_fisicos_observacao: null,
        patologias_associadas: null,
        uso_medicamentos: null,
        exames_bioquimicos: null,
        historico_familiar: null,
        apetite: '1',
        mastigacao: '1',
        habito_intestinal: '1',
        escala_bristol: '0',
        frequencia_evacuacao: null,
        laxante_uso: 'N',
        habito_urinario: null,
        habito_urinario_frequencia: null,
        coloracao_urina: '1',
        unha_observacao: null,
        cabelo: '1',
        cabelo_observacao: null,
        recordatorio_alimentar: null,
        unha: '1',
        urina_observacao: null,
        ingestao_hidrica: null,
        observacao_clinica: null,
        sintomas: null,
        tipos_alimentos_consumido_final_semana: null,
        suplementos_alimentares: null,
        alergia_alimentar: null,
        intolerancias_alimentares: null,
        observacao_alimentar: null,
        apetite_observacao: null,
        mastigacao_observacao: null,
        habito_intestinal_observacao: null,
        kg_acucar_mes: null, // decimal
        tipo_oleo_outro: null,
        tipo_oleo_usado: '1',
        refeicoes_em_casa: 'N',
        refeicao_casa_vezes_semana: null,
        refeicoes_variadas: 'N',
        refeicao_variadas_vezes_semana: null
    });
    let [aba, alterarAba] = useState('habitosVida'); // habitosVida, avaliacaoClinica, habitosAlimentares
    let [salvando, alterarSalvando] = useState(false);

    // inicializa
    useEffect(() => {

        // verifica se é edição
        if(dados.pk_cad_anamnese !== null){

            alterarModo('edicao');
            consultarId();

        }else{
            alterarDados({
                ...dados,
                fk_cad_paciente: idpaciente,
                fk_cad_agenda: idagendamento
            });
        }

    }, []);

    // consulta id
    async function consultarId(){
            alterarCarregando(true);

        try{

            // faz a requisição
            let { data } = await axios.get(`/anamnese/${dados.pk_cad_anamnese}`);
            alterarDados({
                ...dados,
                ...data.dados
            });
            
            // finaliza
            alterarCarregando(false);

        }catch({response}){}

    }

    // ao alterar registro
    function eventoAlterarDados(e){
        dados[e.target.name] = e.target.value;
        if(e.target.type === 'checkbox'){
            dados[e.target.name] = e.target.checked ? 'S' : 'N';
        }
        alterarDados({...dados});
    }

    // salva
    async function salvar(){
        alterarSalvando(true);

        try{

            // faz a requisição
            let { data } = await axios.post(`/anamnese`, dados);
            alterarSalvando(false);
            retornar(true);

        }catch({response}){}

    }

    // volta a tela anteiror
    function retornar(paraListagem = false){
        history.goBack();
    }

    // carregando
    if(carregando){
        return <div>Carregando...</div>
    }

    return <>
        <Card className='border-0 shadow-sm'>
            <Card.Header className='border-0 bg-white pb-0'>
                <Card.Title className='mb-0'>Cadastro Anamnese</Card.Title>
            </Card.Header>
            <Card.Body>
                <Form.Group>
                    <Form.Label>Caso Clínico</Form.Label>
                    <Form.Control 
                        as='textarea'
                        rows='3'
                        maxLength='65535'
                        value={dados.caso_clinico || ''}
                        name='caso_clinico'
                        onChange={eventoAlterarDados}
                    />
                </Form.Group>
            </Card.Body>
        </Card>

        <Tab.Container 
            defaultActiveKey={aba} 
            activeKey={aba} 
            onSelect={key => alterarAba(key)}
        >
            <Row className='mt-4 mb-5'>
                <Col md={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="habitosVida">
                            <FormularioHabitosVida 
                                dados={dados}
                                alterarDados={alterarDados}
                                alterarAba={alterarAba}
                                eventoAlterarDados={eventoAlterarDados}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="avaliacaoClinica">
                            <FormularioAvaliacaoClinica 
                                dados={dados}
                                alterarDados={alterarDados}
                                alterarAba={alterarAba}
                                eventoAlterarDados={eventoAlterarDados}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="habitosAlimentares">
                            <FormularioHabitosAlimentares 
                                dados={dados}
                                alterarDados={alterarDados}
                                alterarAba={alterarAba}
                                eventoAlterarDados={eventoAlterarDados}
                            />
                        </Tab.Pane>
                    </Tab.Content>

                    <SalvarFormulario 
                        salvando={salvando}
                        cancelar={() => {
                            // history.push('/painel/pacientes');
                            retornar();
                        }}
                        onClick={() => salvar()}
                    />
                </Col>
                <Col md={3}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="habitosVida">Hábitos de vida</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="avaliacaoClinica">Avaliação Clínica</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="habitosAlimentares">Hábitos Alimentares</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
        </Tab.Container>
    </>
}

function SalvarFormulario({
    salvando,
    cancelar,
    onClick
}){
    return <Form.Group className="d-flex justify-content-around my-4">
        <Button variant="danger" onClick={cancelar}>Cancelar</Button>
        <Button variant="success" disabled={salvando} onClick={onClick}>
            {salvando ? 
                <>
                    <FontAwesomeIcon className="icone" pulse icon={["fas", 'spinner']} />
                    <span>Salvando</span>
                </> : 
                <span>Salvar</span>
            }
        </Button>
    </Form.Group>
}

